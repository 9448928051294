import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from 'src/app/services/data.service';

import { CapitalizePipe } from '../../pipes/capitalize.pipe';

@Component({
    selector: 'app-dormancy',
    templateUrl: './dormancy.component.html',
    styleUrls: ['./dormancy.component.scss']
})
export class DormancyComponent implements OnInit {
    public contract = this.dataService.getContractData();
    private contractData = this.dataService.getSearchData();

    public contractRewards;
    public alerts =[];
    public loading:boolean = false;

    constructor(private router: Router, private dataService: DataService) { }

    ngOnInit() {
        if(!this.contractData) {
            this.router.navigate(['/'], { queryParamsHandling: 'merge' });
        } else {
            this.contractRewards = this.contractData[0];
        }
    }

    goBack() {
        this.dataService.saveContractData(null);
        this.dataService.saveSearchData(null);
        
        this.router.navigate(['/'], { queryParamsHandling: 'merge' });
    }
}
