import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HttpService } from 'src/app/services/http.service';
import { DataService } from 'src/app/services/data.service';

import { CapitalizePipe } from '../../pipes/capitalize.pipe';

declare const jquery:any;
declare const $:any;

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    public contract = this.dataService.getContractData();
    private contractData = this.dataService.getSearchData();
    private emailData = this.dataService.getEmail();

    public contractRewards;

    public loading:boolean = false;
    public alerts = [];

    constructor(private router:Router, private dataService:DataService, private httpService:HttpService) { }

    ngOnInit() {
        if(!this.contractData) {
            this.router.navigate(['/'], { queryParamsHandling: 'merge' });
        } else {
            this.contractRewards = this.contractData[0];
        }
    }

    scrollToTop() { $('html, body').animate({scrollTop:'0px'}, 250); }//scroll page to top

    register() {
        this.loading = true;
        this.alerts = [];
        
        let contractNumber = this.contract.contractNumber;

        let formData = {
            'contractNumber' : contractNumber,
            'rewardValue' : this.contractRewards.rewardValue,
            'emailAddress': this.emailData,
            'zipCode' : this.contract.zipCode
        }

        let url = 'rewards/' + contractNumber +'/redeem';

        this.httpService.sendPostRequest(url, formData)
            .subscribe(res => {
                this.loading = false;
                if (res.successFlag === 'Y') {
                    this.dataService.saveRedeemedData(res);
                    this.router.navigate(['/confirmation'], { queryParamsHandling: 'merge' });
                } else {
                    this.showErrorMessage('There was an error getting data. Please try again.');
                }
            },
                error => {
                    this.loading = false;
                    this.showErrorMessage('There was an error getting data. Please try again.');
                });
        this.scrollToTop();
    }

    goBack() {
        this.router.navigate(['/contact-info'], { queryParamsHandling: 'merge' });
    }

    showErrorMessage(msg) {
        this.alerts = [];

        this.alerts.push({ 
            type: 'danger',
            message: msg 
        });

        this.scrollToTop();
    }
}
