import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';

declare const jquery:any;
declare const $:any;

@Component({
    selector: 'app-contract-search',
    templateUrl: './contract-search.component.html',
    styleUrls: ['./contract-search.component.scss']
})
export class ContractSearchComponent implements OnInit {
    public alerts =[];
    private contractNum;
    private zip;

    public loading:boolean = false;

    searchContractForm:FormGroup;
    modalRef:BsModalRef;
    
    constructor(private modalService: BsModalService, private httpService: HttpService, private router: Router, private dataService: DataService) {
        this.searchContractForm = new FormGroup({
            'contractNumber': new FormControl(null, [Validators.required]),
            'zipCode': new FormControl(null, [Validators.required, Validators.minLength(5), Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')])
        });
    }

    ngOnInit() {
        this.scrollToTop();
    }

    scrollToTop() { $('html, body').animate({scrollTop:'0px'}, 250); }//scroll page to top

    searchContract(vv) {//console.log('form submitted', this.searchContractForm.value);
        //search for user inputted contract & goto appropiate page according to response
        let contractNumber = this.searchContractForm.value.contractNumber;
        let zipcode = this.searchContractForm.value.zipCode;
        let url = 'rewards/' + contractNumber +'/' + zipcode;

        this.loading = true;
        this.alerts = [];

        this.httpService.sendGetRequest(url)
                .subscribe(res => {
                    this.loading = false;

                    if(res) {
                        if(res.statusCode === 404) {
                            this.showErrorMessage('We\'re sorry, the information you entered does not match any of our records. Please try again.');
                        } else {
                            this.dataService.saveContractData(this.searchContractForm.value);
                            this.dataService.saveSearchData(res);

                            let response  = res[0];

                            if(response.redeemed) {
                                this.router.navigate(['/reward-status'], { queryParamsHandling: 'merge' });
                            } else if(response.dormantDays && response.dormantDays > 0) {
                                this.router.navigate(['/reward-dormant'], { queryParamsHandling: 'merge' });
                            } else {
                                this.router.navigate(['/contact-info'], { queryParamsHandling: 'merge' });
                            }
                        }
                    } else {
                        this.showErrorMessage('We\'re sorry, but your contract is not eligible for rewards at this time.');  
                    }
                },
            error => {
                this.loading = false;
                this.showErrorMessage('There was an error getting data. Please try again.');
            });

        this.scrollToTop();
    }

    openModal(template:TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    showErrorMessage(message) {
        this.alerts.push({ 
            type: 'danger',
            message: message 
        });
    }
}
