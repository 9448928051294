import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';

declare const jquery:any;
declare const $:any;

@Component({
    selector: 'app-contact-info',
    templateUrl: './contact-info.component.html',
    styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {
    public contract = this.dataService.getContractData();
    private contractData = this.dataService.getSearchData();
    private emailData = this.dataService.getEmail();

    public contractRewards;
    public alerts = [];

    public emailForm:FormGroup;

    public emailMatch:boolean = true;
    public loading:boolean = false;

    constructor(private router: Router, private httpService: HttpService, private dataService: DataService) {
        this.emailForm = new FormGroup({
            'email': new FormControl(null, [Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)]),
            'confirmEmail': new FormControl(null, [Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)])
        });
    }

    ngOnInit() {          
        if (!this.contractData) {
            this.router.navigate(['/'], { queryParamsHandling: 'merge' });
        } else {
            this.scrollToTop();

            this.contractRewards = this.contractData[0];

            if(this.emailData) {
                this.emailForm.setValue({
                    'email': this.emailData,
                    'confirmEmail': this.emailData
                });
            }
        }
    }

    scrollToTop() { $('html, body').animate({scrollTop:'0px'}, 250); }//scroll page to top

    submitEmail() {//console.log(this.emailForm.value);
        this.emailMatch = true;

        if(this.emailForm.value.email !== this.emailForm.value.confirmEmail) { 
            this.emailMatch = false;
            return false;
        }

        this.dataService.saveEmail(this.emailForm.value.email);
        this.router.navigate(['/register-for-reward'], { queryParamsHandling: 'merge' });
    }

    showError() {
        this.showErrorMessage('Please contact us at <a href=\"tel:8446276006\">(844) 627-6006</a>');
    }

    showErrorMessage(msg) {
        this.alerts = [];

        this.alerts.push({ 
            type: 'success',
            message: msg 
        });

        this.scrollToTop();
    }

    goBack() {
        this.dataService.saveContractData(null);
        this.dataService.saveSearchData(null);
        this.dataService.saveEmail(null);
        
        this.router.navigate(['/'], { queryParamsHandling: 'merge' });
    }

    close(alert){
        this.alerts.splice(this.alerts.indexOf(alert), 1);
    }
}
