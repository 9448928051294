import { Component, OnInit } from '@angular/core';

declare const jquery:any;
declare const $:any;

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    public currYear;
    public footerLinks;
    
    constructor() { }
    
    ngOnInit() {
        this.currYear = new Date().getFullYear();
    
        this.footerLinks = {
            'footerLinks': [
                {
                    'name': 'Contact Us',
                    'url': 'https://www.cinchhomeservices.com/contact-us'
                },
                {
                    'name': 'Disclosure',
                    'url': 'https://www.cinchhomeservices.com/disclosure'
                },
                {
                    'name': 'Privacy Policy',
                    'url': 'https://www.cinchhomeservices.com/privacy-policy'
                },
                {
                    'name': 'Terms of Use',
                    'url': 'https://www.cinchhomeservices.com/terms-of-use'
                }
            ]
        }
    }
}
