import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    private urlParam:string = environment.apiUriPrefix + '/';
    private httpOptions;

    constructor(private http:HttpClient) {
        this.httpOptions = { headers:new HttpHeaders(this.getHeaders()) };
    }
    
    getHeaders() {
        let headers = {};

        headers["x-api-key"] = environment.xApiKey,
        headers["x-client-source"] = 'my-account-2';
        headers["Content-Type"] ='application/json';
        
        return headers;
    }
    
    sendGetRequest(url:string):Observable<any> {
        return this.http
            .get(this.urlParam + url, this.httpOptions)
            .map((data) => {
                return data;
            })
            .catch(this.handleError);
    }

    sendPostRequest(url:string, params):Observable<any> {
        return this.http
            .post(this.urlParam + url, params, this.httpOptions)
            .map((data) => {
                return data;
            })
            .catch(this.handleError);
    }

    sendPutRequest(url:string, params):Observable<any> {
        return this.http
            .put(this.urlParam + url, params)
            .map((response:Response) => {
                return response.json();
            })
            .catch(this.handleError);
    }

    private handleError(error:Response) {
        return Observable.throw(error.json());
    }
}
