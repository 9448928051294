import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from 'src/app/services/data.service';

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
    public contract = this.dataService.getContractData();
    private contractData = this.dataService.getSearchData();
    public redeemedData = this.dataService.getRedeemedData();

    public contractRewards;
    public processedDate;
    public alerts =[];
    public loading:boolean = false;

    constructor(private router:Router, private dataService:DataService) { }

    ngOnInit() {
        if(!this.contractData) {
            this.router.navigate(['/'], { queryParamsHandling: 'merge' });
        } else {
            this.contractRewards = this.contractData[0];
            this.processedDate = new Date();
        }
    }

    startOver() {
        //erase stored data and route back to landing page
        this.dataService.saveContractData(null);
        this.dataService.saveSearchData(null);
        this.dataService.saveEmail(null);
        this.dataService.saveRedeemedData(null);

        this.router.navigate(['/'], { queryParamsHandling: 'merge' });
    }
}
