import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    private searchData;
    private contractData;
    private emailData;
    private redeemedData;

    constructor() { }

    saveContractData(data) { 
        this.contractData = data;
    }

    getContractData() { 
        return this.contractData;
    }
    // getContractData() { return {"contractNumber": "10000780", "zipCode": "77479"}; }

    saveSearchData(data) { 
        this.searchData = data;
    }

    getSearchData() { 
        return this.searchData;
    }
    /* getSearchData() {
        return {"contractRewards":[{"zipCode":"98002","rewardValue":"$25 REWARD CARD","rewardEligible":true,"registeredEmail":"noreply@cchs.com","registeredDate":1576800000000,"redeemed":true,"redeemedDate":1576800000000,"processed":true,"processedDate":1576800000000,"firstName":"DEBRA A","lastName":"TERREL","streetAddress":"1451 31ST ST SE","city":"AUBURN","state":"WA","dormantDays":0}]};
    } */

    saveEmail(data) {
        this.emailData = data;
    }
    
    getEmail() {
        return this.emailData;
    }

    saveRedeemedData(data) {
        this.redeemedData = data;
    }

    getRedeemedData() {
        return this.redeemedData;
    }
}
