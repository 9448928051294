import { Component, OnInit } from '@angular/core';
import { WebContentService } from '../../services/web-content.service';

declare const jquery:any;
declare const $:any;

@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.scss']
})
export class UnderConstructionComponent implements OnInit {

  private pageWebContent:any;
  private pageContent;

  private showPage:boolean = false;

  constructor(private webContentService:WebContentService) { }

  ngOnInit() {
      setTimeout(() => { 
          this.pageWebContent = this.webContentService.getErrorPageContent();//console.log('page web content=', this.pageWebContent);

          //wire web content for page from Liferay or default it
          if(this.pageWebContent) {
              this.pageContent = this.pageWebContent;
          } else {
              this.pageContent = [
                  {
                      'errorCode': '500',
                      'errorHeader':'Sorry, we\'re doing some work on the site.',
                      'subheaderText': 'We will be back shortly.',
                      'paragraphContent': null,
                      'linkLabels': []
                      //'btnUrl': 'https://www.cinchhomeservices.com/',
                      //'btnText': 'Cinch homesite'
                  }
              ];
          }

          this.showPage = true;
      }, 500);

      //randomize page image and send to view
      setTimeout(() => { 
          if(this.pageWebContent) { 
              let randNum = this.getRandomInt(1,3);console.log('chosen img=' + randNum);
              let x = randNum - 1;
              let chosenImg = this.pageContent[1].errorImages[x];

              $('.error-module__content').css({
                  'background':'url('+ chosenImg+ ') 0 0 no-repeat',
                  'background-size' : 'cover'
              });

              $('.error-module__content_mobile').css({
                  'width':'100%',
                  'padding-top':'50%',
                  'background':'url('+ chosenImg+ ') 0 0 no-repeat',
                  'background-size' : 'contain'
              });
          } else {
              $('.error-module__content').css({
                  'background':'url(../../assets/img/error/Error-'+ this.getRandomInt(3,5) +'.jpg) 0 0 no-repeat',
                  'background-size' : 'cover'
              });

              $('.error-module__content_mobile').css({
                  'width':'100%',
                  'padding-top':'50%',
                  'background':'url(../../assets/img/error/Error-'+ this.getRandomInt(3,5) +'.jpg) 0 0 no-repeat',
                  'background-size' : 'contain'
              });
          } 
      }, 600);
  }

  getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.ceil(max);
      return Math.floor(Math.random() * (max-min)) + min;
  }


}
