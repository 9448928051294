import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { HttpService } from './services/http.service';

import { WebContentService } from './services/web-content.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    /////To display the 'under construction page' for site maintenance, change the siteMaintenance variable to true//////
    private siteMaintenance:boolean = false;
    constructor(private router:Router, private webContentService:WebContentService,  private httpService:HttpService,) {}
    ngOnInit() {
        if(this.siteMaintenance) { 
            this.webContentService.saveMaintenanceFlag(true);
            this.router.navigate(['page/503']); 
         } else {
             this.webContentService.saveMaintenanceFlag(false);
             let errorPageContentURL = 'web/content/MYACCOUNT-HTTP-ERROR-CONTENT';
             this.getWebContent(errorPageContentURL);
         }
     }
     getWebContent(url) {
        this.httpService.sendGetRequest(url)
            .subscribe(
                results => {//console.log(results);

                            this.webContentService.saveErrorPageContent(results);//console.log(this.webContentService.getErrorPageContent());

                    },
                error => {console.log(error); }
            )
    }
}
