import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WebContentService {
    private flag;
    private content2;

    constructor() { }


    //maintenace flag
    saveMaintenanceFlag(data) { this.flag = data; }
    getMaintenanceFlag() { return this.flag; }

        //404 & 503 pages
        saveErrorPageContent(data) { this.content2 = data; }
        getErrorPageContent() { return this.content2; }
}