import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';

//ngx-bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';

//componenets
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContractSearchComponent } from './components/contract-search/contract-search.component';
import { RedeemedComponent } from './components/redeemed/redeemed.component';
import { DormancyComponent } from './components/dormancy/dormancy.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { RegisterComponent } from './components/register/register.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';

//services
import { HttpService } from './services/http.service';
import { DataService } from './services/data.service';

//pipes
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { UnderConstructionComponent } from './pages/under-construction/under-construction.component';

@NgModule({
    declarations: [
        CapitalizePipe,
        AppComponent,
        ContractSearchComponent,
        HeaderComponent,
        FooterComponent,
        RedeemedComponent,
        DormancyComponent,
        ContactInfoComponent,
        RegisterComponent,
        ConfirmationComponent,
        UnderConstructionComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        NgbModule,
        HttpClientModule,
        AlertModule.forRoot(),
        ModalModule.forRoot()
    ],
    providers: [
        HttpService,
        DataService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
