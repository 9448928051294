import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name:'capitalize'})
export class CapitalizePipe implements PipeTransform {
    transform(value:any, args:string[]):any {
        if(value) { return value.toLowerCase().replace(/\b\w/g, l => l.toUpperCase()); }

        return value;
    }
}
