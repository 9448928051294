import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//components
import { ContractSearchComponent } from './components/contract-search/contract-search.component';
import { RedeemedComponent } from './components/redeemed/redeemed.component';
import { DormancyComponent } from './components/dormancy/dormancy.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { RegisterComponent } from './components/register/register.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { UnderConstructionComponent } from './pages/under-construction/under-construction.component';

const appRoutes:Routes = [
    {
        path: '',
        component: ContractSearchComponent
    },
    {
        path: 'reward-status',
        component: RedeemedComponent
    },
    {
        path: 'reward-dormant',
        component: DormancyComponent
    },
    {
        path: 'contact-info',
        component: ContactInfoComponent
    },
    {
        path: 'register-for-reward',
        component: RegisterComponent
    },
    {
        path: 'confirmation',
        component: ConfirmationComponent
    },
    { path: 'page/503',
    component: UnderConstructionComponent },
    {     
        path: '**', 
        redirectTo: '' 
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
